import { Component, inject, OnInit } from "@angular/core";
import { ApplicationService } from "../../../../services/application.service";
import { AreaActionService } from "../../../../services/area-action.service";
import { Statistic } from "../../../../classes/flow/Questionnaire/Statistic";
import { StatisticService } from "../../../../services/statistic.service";
import { AreaAction } from "../../../../classes/flow/AreaActions/AreaAction";
import { Coordinator } from "../../../../classes/flow/session/impl/Coordinator";
import { downloadCSVFile } from "../../../../helpers/downloadCSV";
import { CurrencyPipe } from "@angular/common";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: "app-dashboard-statistics",
  templateUrl: "./dashboard-statistics.component.html",
  styleUrls: ["./dashboard-statistics.component.less"],
})
export class DashboardStatisticsComponent implements OnInit {
  private applicationService: ApplicationService;
  private statisticService: StatisticService;
  private areaActionService: AreaActionService;
  private currencyPipe: CurrencyPipe;

  public municipalities: string[];

  public tabs: {
    time: {
      statisticsYear: (Statistic | null)[];
      total: Statistic | null;
    };
    action: {
      statistics: (Statistic | null)[];
    };
  };

  public cachedAreaActions: AreaAction[];

  private earliestYear = 2023;
  private aaLoaded = false;

  public constructor() {
    this.applicationService = inject(ApplicationService);
    this.statisticService = inject(StatisticService);
    this.areaActionService = inject(AreaActionService);
    this.currencyPipe = new CurrencyPipe("nl");

    this.municipalities = this.applicationService.session.user instanceof Coordinator ? this.applicationService.session.user.municipalities : [];

    this.tabs = {
      time: {
        statisticsYear: [],
        total: null,
      },
      action: {
        statistics: [],
      },
    };

    this.cachedAreaActions = [];
  }

  public async ngOnInit(): Promise<void> {
    await this.loadYears();

    this.cachedAreaActions = await this.areaActionService.all(false, true);
    this.cachedAreaActions.forEach((val) => this.tabs.action.statistics.push(null));
  }

  public async loadYears(): Promise<void> {
    const currentYear = new Date().getFullYear() + 1;
    const toFetchYears = [];
    for (let year = this.earliestYear; year <= currentYear; year++) {
      toFetchYears.unshift(year);
    }

    toFetchYears.forEach((val) => this.tabs.time.statisticsYear.push(null));

    this.tabs.time.total = await this.statisticService.getStatistic();

    for (const [index, year] of toFetchYears.entries()) {
      this.tabs.time.statisticsYear[index] = await this.statisticService.getStatistic({
        startDate: new Date(new Date(new Date().setFullYear(year - 1, 0, 1)).setHours(4, 0, 0, 0)),
        endDate: new Date(new Date(new Date().setFullYear(year, 0, 1)).setHours(4, 0, 0, 0)),
      });
    }
  }

  public async loadAreaActions(): Promise<void> {
    this.aaLoaded = true;
    for (const [index, aa] of this.cachedAreaActions.entries()) {
      this.tabs.action.statistics[index] = await this.statisticService.getStatistic(
        {
          areaActionID: aa.id,
        },
        aa
      );
    }
  }

  public async handleTabChange(event: MatTabChangeEvent): Promise<void> {
    switch (event.index) {
      case 0:
        break;
      case 1:
        if (this.cachedAreaActions.length !== this.tabs.action.statistics.filter((stat) => stat).length || !this.aaLoaded) await this.loadAreaActions();
        break;

      default:
        break;
    }
  }

  public async export(): Promise<void> {
    downloadCSVFile(this.fixteamExport(), "statistiekenExport");
  }

  private fixteamExport(): string {
    let content = "periode,type,categorie,aantal,eenheid,kosten totaal,kosten gemiddeld,gemiddelde doorloop tijd in dagen\n";

    const total = this.tabs.time.total!;
    content += `Totaal,Totaal,Totaal,${total.count},aanvragen,${this.transformCurrency(total.totalPrice)},${this.transformCurrency(total.averagePrice)},${
      total.averageTimeInDays
    }\n`;

    for (const category of total.formatedReportCategories.filter((cat) => cat.type === "select")) {
      for (const type of category.selectValues ?? []) {
        content += `Totaal,${category.name},${type.value},${type.answeredCount},keer beantwoord,${this.transformCurrency(type.cost)},,\n`;
      }

      for (const category of total.formatedReportCategories.filter((cat) => cat.type === "cost")) {
        for (const type of category.costValues ?? []) {
          content += `Totaal,product,${category.name},${type.count},producten,${this.transformCurrency(type.totalPrice)},,\n`;
        }
      }
    }

    for (const statistic of this.tabs.time.statisticsYear) {
      if (!statistic) break;
      content += `${statistic.selection.timeRange?.start?.getFullYear() ?? "undefined"},Totaal,Totaal,${statistic.count},aanvragen,${this.transformCurrency(
        statistic.totalPrice
      )},${this.transformCurrency(statistic.averagePrice)},${statistic.averageTimeInDays}\n`;
      for (const category of statistic.formatedReportCategories.filter((cat) => cat.type === "select")) {
        for (const type of category.selectValues ?? []) {
          content += `${statistic.selection.timeRange?.start?.getFullYear() ?? ""},${category.name},${type.value},${
            type.answeredCount
          },keer beantwoord,${this.transformCurrency(type.cost)},,\n`;
        }
      }

      for (const category of statistic.formatedReportCategories.filter((cat) => cat.type === "cost")) {
        for (const type of category.costValues ?? []) {
          content += `${statistic.selection.timeRange?.start?.getFullYear() ?? ""},product,${category.name},${type.count},producten,${this.transformCurrency(
            type.totalPrice
          )},,\n`;
        }
      }
    }

    return content;
  }

  private transformCurrency(value: number): string | null {
    return this.currencyPipe.transform(value, "EUR", "symbol", ".2-2", "en");
  }
}
